import React from "react"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Contact from "../components/contact"
import Emoji from "a11y-react-emoji"
const CVPage = _ => (
  <Layout footer={true}>
    <Seo title="CV" />
    <div classNameName="container">
      <article classNameName="prose-lg">
        <div className="mb-12">
          <h1 className="text-3xl text-center font-bold leading-normal mt-0 mb-3">
            Gabriel Lee
          </h1>
          <div
            id="content"
            className="prose max-w-none mb-10"
          >
            <h2>
              <a className="heading-anchor" href="#full-stack-engineer">
                {""}
              </a>
              Software Engineer
            </h2>
            <p>
              Self-taught to design and build websites at the start of the
              millennium. Proficient in HTML5, CSS, JavaScript/TypeScript, Python and Go.
              Familiar with modern javascript frameworks, ReactJS, NodeJS,
              ExpressJS.
            </p>
            <p>
              An intuitive thinker, focused on solving problems and passionate
              in creating functional and beautiful products. I believe that
              learning never stops and there will always be new challenges to
              overcome. Reliable team player that is able to collaborate well
              and ensure projects are completed on time.
            </p>
            <ul>
              <li>
                <a href="https://gabrielleeyj.com">gabrielleeyj.com</a>
              </li>
              <li>
                <a href="mailto:hello@gabrielleeyj.com">
                  hello@gabrielleeyj.com
                </a>
              </li>
              <li>
                <a href="https://github.com/gabrielleeyj">Github</a>
              </li>
              <li>
                <a href="https://codestats.net/users/melchsee">CodeStats</a>
              </li>
              <li>
                <a href="https://www.linkedin.com/in/gabrielleeyj/">
                  LinkedIn Profile
                </a>
              </li>
              <li>
                Singapore <Emoji symbol="🇸🇬" label="Singapore" />. That tiny red
                dot.
              </li>
            </ul>
            <h2>
              <a className="heading-anchor" href="#technical-skills">
                {""}
              </a>{" "}
              Technical Skills
            </h2>
            <ul>
              <li>
                <strong>Languages</strong> - Bash/Shell, Go, Rust, C++, JavaScript, TypeScript, Python, HTML/CSS.
              </li>
              <li>
                <strong>Tools/Environment</strong> - Grafana, Git, Monday, Slack, Github, Gitlab, Docker, Heroku, Redis, Jira,
                Confluence, Bitbucket, Adobe Photoshop, Adobe Illustrator, Figma, Neovim.
              </li>
              <li>
                <strong>Frameworks/Libraries</strong>
                <ul>
                  <li>Go - Gin, Gorm, Gorilla, Pgx, Echo</li>
                  <li>JS/TS - ReactJS, NodeJS, ExpressJS, VueJS, NextJS, Webpack, Bootstrap, Material</li>
                  <li>Python - Jinjja, Django, Tornado</li>
                </ul>
              </li>
              <li>
                <strong>Data</strong> - PostgreSQL, MySQL, GraphQL, JSON,
                MongoDB, BoltDb, Firebase - Firestore, RTDB, Storage, BigQuery, OpenSearch, ElasticSearch
              </li>
            </ul>
            <h2>
              <a className="heading-anchor" href="#experience">
                {""}
              </a>
              Experience
            </h2>
            <h3>
              <a className="heading-anchor" href="#fs-engineer">
                {""}
              </a>
              Full Stack Engineer
            </h3>
            <p>
              <strong>
                <a href="https://transcelestial.com">Transcelestial</a>
              </strong>{" "}
              - <em> Mar 2022 - Present | Singapore</em>
            </p>
            <ul>
              <li>Technologies used - JS - ReactJS, Golang - Echo, BoltDB, C++ - OpenCV, GCP - K8s, Grafana, Shell, Gitlab</li>
              <li>Develop internal tools for Platform.</li>
              <li>Improve REST API endpoints for Backend.</li>
              <li>Create unit tests with gomock and testing.T</li>
              <li>Write documentations on architecture.</li>
              <li>Improve Core Technology for Pointing Acquisition Targeting System to allow multi beacon setup.</li>
              <li>Attend to customer support issues and requests.</li>
              <li>Fix Camera Stream issue conflicting a multi container setup.</li>
              <li>Refactor propietary scan logic reducing scan times by 300% from O(N)^2 to O(N)</li>
              <li>Support Software Issues with Jig on manufacturing line.</li>
              <li>Built software improvements for new Hardware Rev.</li>
              <li>Work on Free Space Optics Link Testbed setup.</li>
              <li>Maintain Cloud Infrastructure.</li>
              <li>Setup CI Pipelines for Integration Testing.</li>
              <li>Rebuild Cloud Infrastructure for logging system saving cost by 50%.</li>
              <li>Interview Candidates.</li>
              <li>Mentor Interns.</li>
            </ul>
            <h3>
              <a className="heading-anchor" href="#fs-engineer">
                {""}
              </a>
              Full Stack Engineer
            </h3>
            <p>
              <strong>
                <a href="https://bitsmedia.com">Bitsmedia</a>
              </strong>{" "}
              - <em> Jul 2021 - Mar 2022 | Singapore</em>
            </p>
            <ul>
              <li>Technologies used - ReactJS, Firebase, Python Jinja, NodeJS, Redis, Kubernetes</li>
              <li>Build new features for Frontend Platform.</li>
              <li>Develop microservices and tools on Backend. </li>
              <li>Implement REST API endpoints for Backend.</li>
              <li>Create unit tests with Jest.</li>
              <li>Write documentations on codebase.</li>
              <li>Integrate Web Payment Services.</li>
            </ul>
            <h3>
              <a className="heading-anchor" href="#software-developer">
                {""}
              </a>
              Software Developer
            </h3>
            <p>
              <strong>
                <a href="https://softwarerisk.com">Software Risk</a>
              </strong>{" "}
              - <em> Jan 2021 - Jun 2021 | Singapore</em>
            </p>
            <ul>
              <li>
                Technologies used - ReactJS, Redux, Golang, Docker, Kubernetes,
                PostgreSQL.
              </li>
              <li>Build new features for Web Platform.</li>
              <li>
                Fix bugs from existing Platform and implemented enhancements.
              </li>
              <li>Develop microservices and tools for Backend. </li>
              <li>Implement new REST API endpoints for Backend.</li>
              <li>Create unit test prior to deployment.</li>
              <li>Update user stories in sprint planning.</li>
            </ul>

            <h3>
              <a className="heading-anchor" href="#wdu">
                {""}
              </a>
              1 Web & Design Officer
            </h3>
            <p>
              <strong>Public Affairs Department</strong> -{" "}
              <em>May 2018 - November 2020 | Singapore</em>
            </p>
            <ul>
              <li>Create publicity materials</li>
              <li>
                Design Unicorn - Product Design, Graphic Design, Illustrations,
                Book Design, Building Design, Interior Design, Web Design.
              </li>
              <li>
                Designed UX Mockup for Website
                [https://police.gov.sg](https://police.gov.sg),
                [https://police.gov.sg/spf200](https://police.gov.sg/spf200)
              </li>
              <li>Develop Intranet Website.</li>
            </ul>
            <h3>
              <a className="heading-anchor" href="#dto">
                {""}
              </a>
              Divisional Technology Officer
            </h3>
            <p>
              <strong>Police Security Command</strong> -{" "}
              <em>April 2013 - April 2018 | Singapore</em>
            </p>
            <ul>
              <li>
                Developed new Portable Security Equipment for Surveillance.
              </li>
              <li>Evaluate new Radio System Technologies.</li>
              <li>Implement Security Systems at residences.</li>
            </ul>
            <h3>
              <a className="heading-anchor" href="#pso">
                {""}
              </a>
              Personnel Security Officer
            </h3>
            <p>
              <strong>Police Security Command</strong> -{" "}
              <em>2009 - Apr 2018 | Singapore</em>
            </p>
            <ul>
              <li>
                Ensure the safety and security of Cabinet Ministers, and
                visiting Foreign Dignataries.
              </li>
            </ul>
            <h2>
              <a className="heading-anchor" href="#education">
                {""}
              </a>
              Education
            </h2>
            <h3>
              <a className="heading-anchor" href="#bsc-cysec">
                {""}
              </a>
              Bachelor of Science (Hons) in Cybersecurity and Computer Forensics
            </h3>
            <p>
              <strong>University of Portsmouth - UK</strong> -{" "}
              <em>2021 - 2022 | Singapore</em>
            </p>

            <h3>
              <a className="heading-anchor" href="#dip-in-it">
                {""}
              </a>
              Diploma in Infocomm Technology
            </h3>
            <p>
              <strong>PSB Academy</strong> - <em>2020 - 2021 | Singapore</em>
            </p>
            <h3>
              <a className="heading-anchor" href="#dip-in-psych">
                {""}
              </a>
              Diploma in Applied Psychology
            </h3>
            <p>
              <strong>Raffles College of Higher Education</strong> -{" "}
              <em>2007 - 2008 | Singapore</em>
            </p>
            <h2>
              <a className="heading-anchor" href="#certification">
                {""}
              </a>
              Certifications
            </h2>
            <ul>
              <li>
                Jan 2022:{" "}
                <a href="https://www.credly.com/badges/27d7c73d-1101-4f45-a5f8-3f58b05d7032?source=linked_in_profile">
                  AWS Certified Solutions Architect - Associate
                </a>
              </li>
              <li>
                Dec 2021:{" "}
                <a href="https://www.credly.com/badges/fbd84498-0b8c-4dbd-ae62-d05a888b6d87?source=linked_in_profile">
                  AWS Certified Cloud Practitioner
                </a>
              </li>
              <li>
                Mar 2021:{" "}
                <a href="https://mages.edu.sg/academics/programmes/tech-immersion/full-stack-web-development/">
                  Professional Certificate in Full Stack Web Development Batch 1
                </a>
              </li>
              <li>
                Dec 2020:{" "}
                <a href="https://goschool.sg/">
                  GoSchool - Golang Apprenticeship Programme Batch 1
                </a>
              </li>
              <li>
                Jan 2020:{" "}
                <a href="https://academy.smu.edu.sg/professional-certificate-python-programming-3266">
                  Professional Certificate in Python Programming
                </a>
              </li>
              <li>
                Dec 2019:{" "}
                <a href="https://www.iss.nus.edu.sg/executive-education/course/detail/nicf--mobile-user-experience-design-(sf)">
                  NICF - Mobile User Experience Design
                </a>
              </li>
              <li>
                Oct 2019:{" "}
                <a href="https://bcert.me/bc/html/show-badge.html?b=dqdmjlfv">
                  Certified ScrumMaster
                </a>
              </li>
            </ul>
          </div>
        </div>
      </article>
    </div>

    <Contact />
  </Layout>
)

export default CVPage
